import React, { useState, useEffect,useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PhoneIcon from "@mui/icons-material/Phone";
import styles from "./Navbar.module.css";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import logo from "../../assets/Logo.png";
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import { Button } from "@mui/material";
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import ApiService from "../apiService/ApiService";
import Snackbar from "../ui/CustomSnackbar/customSnackbar";
import { useNavigate } from "react-router-dom";
import AddIcCallRoundedIcon from '@mui/icons-material/AddIcCallRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
function Navbar() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [response, setResponse] = useState({});


  const navOptions = useMemo(() => [
    { title: "Accounts", to: "/", icon: <GroupRoundedIcon /> },
    { title: "Virtual Numbers", to: "/virtual-numbers", icon: <PhoneIcon /> },
    { title: "Search", to: "/search", icon:<PersonSearchRoundedIcon/> },
   { title: "IAM", to: "/iam",icon:<PersonAddAltRoundedIcon/> },
   { title: "Test", to: "/test",icon: <AddIcCallRoundedIcon/> },
   { title: "VN Management", to: "/number-management",icon: <ManageAccountsIcon/> },

   
  ], []);
  const handleFeedback = () => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedMenuItem = navOptions.find(
      (option) => option.to === currentPath,
    );
    if (matchedMenuItem) {
      setActiveMenuItem(matchedMenuItem.title);
    }
  }, [location.pathname, navOptions]);
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated) {
      const bodyGap = document.querySelector(".body_gap");
      if (bodyGap) {
        bodyGap.style.marginLeft = isSidebarOpen ? "150px" : "0px";
      }
      
      const closeSidebar = () => {
        setIsSidebarOpen(false);
      };

      document.body.addEventListener("click", closeSidebar);

      return () => {
        document.body.removeEventListener("click", closeSidebar);
      };
    }
  }, [isSidebarOpen]);
  
  useEffect(() => {
    const currentPath = location.pathname;
    const matchedMenuItem = navOptions.find(
      (option) => option.to === currentPath,
    );
    if (matchedMenuItem) {
      setActiveMenuItem(matchedMenuItem.title);
    }
  }, [location.pathname, navOptions]);


  const handleNavOptionClick = (title) => {
    setActiveMenuItem(title);
    setIsSidebarOpen(false);
  };
  const navigate = useNavigate();

  const handleLogout = () => {

      ApiService.get("api/v1/auth/logout/?webapp=True")
      .then((response) => {
        handleFeedback(response);
        localStorage.removeItem("isAuthenticated");
        navigate("/login");
      })
      .catch((error) => {
        handleFeedback(error.response);
      });
  };
  
  
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  if (!isAuthenticated) {
    return null;
  }

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const sidebarWidth = isSidebarOpen ? "210px" : "60px";

  return (
    <div
      className={styles.side_nav_container}
      style={{ width: sidebarWidth }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.brand} onClick={toggleSidebar}>
        <img src={logo} alt="logo" />
        <h2>Dashboard</h2>
      </div>

      <ul className={styles.nav_menu}>
        {navOptions.map((option, index) => (
          <li key={index}>
            {option.subOptions ? (
              <div>
                <div onClick={option.onClick}>
                  <Link
                    className={`${styles.menu_item} ${styles.telnyx_option}`}
                  >
                    {option.icon}
                    {isSidebarOpen && (
                      <span className={styles.small_text}>{option.title}</span>
                    )}
                  </Link>
                </div>
                {isSidebarOpen && (
                  <ul className={styles.sub_menu}>
                    {option.subOptions.map((subOption, subIndex) => (
                      <li key={subIndex} className={styles.active}>
                        <Link
                          className={`${styles.menu_item} ${styles.telnyx_sub_option}`}
                          to={subOption.to}
                          onClick={() => handleNavOptionClick(subOption.title)}
                        >
                          {subOption.icon}
                          {isSidebarOpen && (
                            <span className={styles.small_text}>
                              {subOption.title}
                            </span>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <div>
              <Link
                onClick={() => handleNavOptionClick(option.title)}
                className={`${styles.menu_item} ${
                  activeMenuItem === option.title ? styles.active : ""
                }`}
                to={option.to}
              >
                {option.icon}
                {isSidebarOpen && option.title}
              </Link>
            
            </div>
            )}
          </li>
        ))}
      </ul>

      {isSidebarOpen && (
      <div className="logout_btn" >
      <Button
            variant="outlined"
            color="error"
            onClick={handleLogout}
            size="large"
            style={{ marginLeft: "6px", position: "fixed", bottom: "10px" }}
          >
            Logout
            <div style={{ marginLeft: "80px", paddingTop: 10,color:'indianred' }}>
              <ExitToAppIcon />
            </div>
          </Button>
        </div>
      )}
       {!isSidebarOpen && (
      <div className={styles.logout2}>
       <ExitToAppIcon  onClick={handleLogout}/>
        </div>
      )}
    </div>
  );
}

export default Navbar;
