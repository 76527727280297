import React, { useEffect, useState, useMemo } from "react";
import {
  Breadcrumbs,
  CircularProgress,
  FormControl,
  MenuItem,
  Button,
  Select,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import ApiService from "../../components/apiService/ApiService";
import Snackbar from "../../components/ui/CustomSnackbar/customSnackbar";
import CustomTable from "../../components/ui/CustomTable/customTable";
import CustomModal from "../../components/ui/CustomModal/customModal";
import Locations from "../../components/vnLocations/locations";

import styles from "../VirtualNumbers/virtualNumbers.module.css";
import commonStyles from "../../components/ui/CommonStyles.module.css";
import pageStyles from "../../components/ui/CommonStyles.module.css";

const vendorOptions = [
  { value: 'Jio', label: 'Jio' },
  { value: 'Tata', label: 'Tata' },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NumberManagement() {
  const [vnRanges, setVnRanges] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [newRange, setNewRange] = useState({
    did_start: "",
    did_end: "",
    vendor_name: "Jio",
    use_case: "General",
    location: "",
  });

  const query = useQuery();
  const rangeId = query.get('rangeId') || '';

  useEffect(() => {
    fetchVnRanges();
  }, [rangeId]);

  const handleFeedback = (response) => {
    if (response && response.data) {
      setResponse(response.data);
    } else if (response && response.message) {
      setResponse({
        message: response.message,
        success: false,
      });
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const fetchVnRanges = () => {
    setShowLoader(true);
    let endpoint = `/api/v1/dashboard/vns-ranges/`;
    if (rangeId) {
      endpoint += `?id=${rangeId}`;
    }

    ApiService.get(endpoint)
      .then((res) => {
        if (res.data.success) {
          setVnRanges(res.data.data);
        }
        setShowLoader(false);
        handleFeedback(res);
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
      });
  };

  const handleCreateRange = () => {
    const { did_start, did_end, vendor_name, use_case, location } = newRange;
    if (!did_start || !did_end || !vendor_name || !use_case || !location) {
      setResponse({
        message: "All fields are required.",
        success: false,
      });
      setShowSnackbar(true);
      return;
    }

    ApiService.post(`/api/v1/dashboard/vns-ranges/`, newRange)
      .then((res) => {
        if (res.data.success) {
          handleFeedback(res);
          fetchVnRanges();
          setShowModal(false);
          setNewRange({
            did_start: "",
            did_end: "",
            vendor_name: "Jio",
            use_case: "General",
            location: "",
          });
        } else {
          handleFeedback(res);
        }
      })
      .catch((err) => {
        handleFeedback(err.response);
      });
  };

  const handleDeleteRange = (id) => {
    ApiService.delete(`/api/v1/dashboard/vns-ranges/?id=${id}`)
      .then((res) => {
        if (res.data.success) {
          handleFeedback(res);
          fetchVnRanges();
        } else {
          handleFeedback(res);
        }
      })
      .catch((err) => {
        handleFeedback(err.response);
      });
  };

  const columns = useMemo(() => [
    { field: "id", headerName: "ID", minWidth: 100, flex: 1 },
    { field: "did_start", headerName: "DID START", minWidth: 150, flex: 1 },
    { field: "did_end", headerName: "DID END", minWidth: 150, flex: 1 },
    { field: "vendor_name", headerName: "Vendor", minWidth: 150, flex: 1 },
    { field: "use_case", headerName: "Use Case", minWidth: 150, flex: 1 },
    { field: "location", headerName: "Location", minWidth: 150, flex: 1 },
    { field: "total_count", headerName: "Total Count", minWidth: 130, flex: 1 },
    { field: "spam_numbers_count", headerName: "Spam Numbers", minWidth: 150, flex: 1 },
    { field: "assigned_numbers_count", headerName: "Assigned Numbers", minWidth: 180, flex: 1 },
    { field: "unassigned_numbers_count", headerName: "Unassigned Numbers", minWidth: 200, flex: 1 },
    { field: "difference_count", headerName: "Difference Count", minWidth: 180, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          startIcon={<DeleteOutlineRoundedIcon />}
          onClick={() => handleDeleteRange(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ], []);

  const displayedRanges = useMemo(() => {
    if (rangeId) {
      return vnRanges.filter(range => String(range.id) === String(rangeId));
    }
    return vnRanges;
  }, [vnRanges, rangeId]);

  return (
    <div className={`${pageStyles.page_container} body_gap`}>
      <Breadcrumbs className={commonStyles.breadcrumbs} aria-label="breadcrumb">
        <Typography variant="body1">Number Management ({displayedRanges.length})</Typography>
      </Breadcrumbs>
      <br/>

      <Accordion
        expanded={showModal}
        onChange={(e, isExpanded) => setShowModal(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "#F0FCF4" }}
        >
          <Typography sx={{ color: "#34cb65" }}>
            Create New VN Range
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '24px', backgroundColor: '#fff' }}>
          {showModal && (
            <Box>
              <Grid container spacing={2} component="form" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" mb={1} sx={{ color: '#555' }}>
                    DID Start
                  </Typography>
                  <TextField
                    placeholder="Enter DID Start"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newRange.did_start}
                    onChange={(e) => setNewRange({ ...newRange, did_start: e.target.value })}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": { borderColor: "#34cb65" },
                        "&.Mui-focused fieldset": { borderColor: "#34cb65" },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" mb={1} sx={{ color: '#555' }}>
                    DID End
                  </Typography>
                  <TextField
                    placeholder="Enter DID End"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newRange.did_end}
                    onChange={(e) => setNewRange({ ...newRange, did_end: e.target.value })}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": { borderColor: "#34cb65" },
                        "&.Mui-focused fieldset": { borderColor: "#34cb65" },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <Typography variant="body2" mb={1} sx={{ color: '#555' }}>
                      Vendor
                    </Typography>
                    <Select
                      value={newRange.vendor_name}
                      onChange={(e) => setNewRange({ ...newRange, vendor_name: e.target.value })}
                      displayEmpty
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "4px",
                          "&:hover fieldset": { borderColor: "#34cb65" },
                          "&.Mui-focused fieldset": { borderColor: "#34cb65" },
                        },
                        backgroundColor: '#fafafa',
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Vendor
                      </MenuItem>
                      {vendorOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" mb={1} sx={{ color: '#555' }}>
                    Use Case
                  </Typography>
                  <TextField
                    placeholder="Enter Use Case"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newRange.use_case}
                    onChange={(e) => setNewRange({ ...newRange, use_case: e.target.value })}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": { borderColor: "#34cb65" },
                        "&.Mui-focused fieldset": { borderColor: "#34cb65" },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <Typography variant="body2" mb={1} sx={{ color: '#555' }}>
                      Location
                    </Typography>
                    <Locations
                      setSelectedLocation={(loc) => setNewRange({ ...newRange, location: loc.id })}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <DialogActions sx={{ padding: '16px 24px' }}>
                <Button
                  onClick={() => setShowModal(false)}
                  color="secondary"
                  variant="outlined"
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCreateRange}
                  color="primary"
                  variant="contained"
                  sx={{
                    backgroundColor: "#34cb65",
                    textTransform: 'none',
                    "&:hover": { backgroundColor: "#2fa754" },
                  }}
                >
                  Create
                </Button>
              </DialogActions>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <div className={styles.accounts_list}>
        {showLoader ? (
          <div className={styles.loader_wrapper}>
            <CircularProgress color="success" size={60} />
          </div>
        ) : (
          <CustomTable
            noRowsOverlayText="No Virtual Number Ranges Available"
            rows={displayedRanges}
            columns={columns}
            exportOption
            pageSize={50}
            showFilters
            checkboxSelection={false}
            style={{ overflowX: "auto" }}
          />
        )}
      </div>

      {showSnackbar && (
        <Snackbar
          message={typeof response.message === 'object' ? response.message.detail : response.message}
          type={response.success ? 'success' : 'error'}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}

export default NumberManagement;
