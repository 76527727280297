import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  CircularProgress,
  FormControl,
  MenuItem,
  Button,
  Select,
} from "@mui/material";
import ApiService from "../../components/apiService/ApiService";
import styles from "./virtualNumbers.module.css";
import commonStyles from "../../components/ui/CommonStyles.module.css";
import pageStyles from "../../components/ui/CommonStyles.module.css";
import Snackbar from "../../components/ui/CustomSnackbar/customSnackbar";
import CustomTable from "../../components/ui/CustomTable/customTable";
import UploadVNModal from "../../components/uploadVNModal/UploadVNModal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomModal from "../../components/ui/CustomModal/customModal";
import VirtualNumberDetails from "./Details/virtualNumberDetails";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function formatTableData(virtualNumbers) {
  return virtualNumbers.map(vn => ({
    ...vn,
    id: vn.id.toString(),
    org: vn.org ? vn.org.toString() : "",
  }));
}

function VirtualNumbers() {
  const [virtualNumbers, setVirtualNumbers] = useState([]);
  const [filteredVirtualNumbers, setFilteredVirtualNumbers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showTextFeild, setShowTextFeild] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [usecase, setUsecase] = useState('');

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const accountId = query.get('accountId') || '';

  useEffect(() => {
    fetchData();
  }, [accountId, location.search]);

  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const fetchData = (showFeedback = true) => {
    setShowLoader(true);
    ApiService.get(`/api/v1/dashboard/retrieve-vns/`)
      .then((res) => {
        if (res.data.success) {
          const formattedData = formatTableData(res.data.data);
          setVirtualNumbers(formattedData);
          setFilteredVirtualNumbers(formattedData);
          setSelectedNumber(
            res.data.data.length > 0 ? res.data.data[0].number : ""
          );
          if (accountId) {
            const filtered = formattedData.filter(vn => vn.org === accountId);
            setFilteredVirtualNumbers(filtered);
          }
        }
        setShowLoader(false);
        if (showFeedback) handleFeedback(res);
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
        console.log("err", err);
      });
  };

  const handleStatusChange = (event, params) => {
    const updatedVirtualNumbers = virtualNumbers.map((vn) => {
      if (vn.id === params.row.id) {
        vn.status = event.target.value;
      }
      return vn;
    });

    ApiService.patch(`/api/v1/dashboard/update-vn/${params.row.id}/`, {
      status: event.target.value,
    })
      .then((res) => {
        setVirtualNumbers(updatedVirtualNumbers);
        setFilteredVirtualNumbers(prevFiltered =>
          prevFiltered.map(vn => vn.id === params.row.id ? { ...vn, status: event.target.value } : vn)
        );
        handleFeedback(res);
      })
      .catch((err) => {
        handleFeedback(err.response);
        console.log("err", err);
      });
  };

  const handleTrialNumberChange = (event, params) => {
    const updatedVirtualNumbers = virtualNumbers.map((vn) => {
      if (vn.id === params.row.id) {
        vn.is_trial_num = event.target.value;
      }
      return vn;
    });
    const requestBody = {
      is_trial_num: event.target.value,
    };
    ApiService.patch(`api/v1/dashboard/update-vn/${params.row.id}/`, requestBody)
      .then((res) => {
        handleFeedback(res);
        setVirtualNumbers(updatedVirtualNumbers);
        setFilteredVirtualNumbers(prevFiltered =>
          prevFiltered.map(vn => vn.id === params.row.id ? { ...vn, is_trial_num: event.target.value } : vn)
        );
      })
      .catch((err) => {
        handleFeedback(err.response);
        console.log("err", err.response);
      });
  };

  const handleDeleteVirtualNumbers = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    const selectedRowsIds = selectedRows;

    ApiService.delete(`/api/v1/dashboard/delete-vns/`, {
      vn_ids: selectedRowsIds,
    })
      .then((res) => {
        const updatedVirtualNumbers = virtualNumbers.filter(
          (vn) => !selectedRowsIds.includes(vn.id)
        );
        setVirtualNumbers(updatedVirtualNumbers);
        setFilteredVirtualNumbers(prevFiltered =>
          prevFiltered.filter(vn => !selectedRowsIds.includes(vn.id))
        );
        setSelectedRows([]);
        setShowDeleteConfirmationModal(false);
        handleFeedback(res);
      })
      .catch((err) => {
        handleFeedback(err.response);
        setShowDeleteConfirmationModal(false);
      });
  };

  function openAccountDetailsInNewWindow(accountId) {
    const url = `/account/${accountId}`;
    window.open(url, '_blank');
  }

  const handleReleaseSingleVN = (number, accountId) => {
    const payload = {
      "single_vn": true,
      "number": number
    };

    ApiService.patch(`/api/v1/dashboard/update-account-details/${accountId}/`, payload)
      .then((response) => {
        setResponse(response.data);
        const updatedVirtualNumbers = formatTableData(response.data.data);
        setVirtualNumbers(updatedVirtualNumbers);
        if (accountId) {
          const filtered = updatedVirtualNumbers.filter(vn => vn.org === accountId);
          setFilteredVirtualNumbers(filtered);
        } else {
          setFilteredVirtualNumbers(updatedVirtualNumbers);
        }
        handleFeedback(response);
      })
      .catch((err) => {
        console.log("Error", err);
        handleFeedback(err?.response);
      });
  };

  const handleUsecaseUpdate = (event, params) => {
    const updatedVirtualNumbers = virtualNumbers.map((vn) =>
      vn.id === params.row.id ? { ...vn, usecase: event.target.value } : vn
    );
    setVirtualNumbers(updatedVirtualNumbers);
    setFilteredVirtualNumbers(prevFiltered =>
      prevFiltered.map(vn => vn.id === params.row.id ? { ...vn, usecase: event.target.value } : vn)
    );

    if (event.key === 'Enter') {
      ApiService.patch(`/api/v1/dashboard/update-vn-usecase/${params.row.id}/`, { usecase: event.target.value })
        .then(handleFeedback)
        .catch((err) => handleFeedback(err.response));
    }
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    { field: "country_code", headerName: "Country Code", flex: 1, minWidth: 150 },
    { field: "number", headerName: "Number", minWidth: 150, flex: 1 },
    { field: "location", headerName: "Location", minWidth: 150, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const isAssigned = params.row.status === "Assigned";
        return (
          <FormControl fullWidth>
            {isAssigned ? (
              <input
                value={params.row.status}
                disabled
                style={{ fontSize: "16px", color: "black" }}
              />
            ) : (
              <Select
                size="small"
                value={params.row.status}
                onChange={(e) => handleStatusChange(e, params)}
              >
                <MenuItem value="Unassigned">Unassigned</MenuItem>
                <MenuItem value="Spam">Spam</MenuItem>
              </Select>
            )}
          </FormControl>
        );
      },
    },
    {
      field: "is_trial_num",
      headerName: "Trial Number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            size="small"
            value={params.row.is_trial_num}
            onChange={(e) => handleTrialNumberChange(e, params)}
          >
            <MenuItem value={false}>False</MenuItem>
            <MenuItem value={true}>True</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    { field: "channel", headerName: "Channel", minWidth: 150, flex: 1 },
    { field: "infrastructure_id", headerName: "Infrastructure Id", minWidth: 150, flex: 1 },
    {
      field: "org",
      headerName: "Account Id",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <button
          onClick={() => openAccountDetailsInNewWindow(params.row.org)}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#1976d2",
          }}
        >
          {params.row.org}
        </button>
      ),
    },
    {
      field: "release",
      headerName: "Release VN",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          sx={{
            ":hover": {
              bgcolor: "#34cb65",
              color: "white",
            },
          }}
          color="success"
          onClick={() => handleReleaseSingleVN(params.row.number, params.row.org)}
        >
          Release
        </Button>
      ),
    },
    {
      field: "fresh",
      headerName: "Fresh",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "usecase",
      headerName: "Use case",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <input
          type="text"
          value={params.row.usecase || ""}
          onChange={(e) => handleUsecaseUpdate(e, params)}
          onKeyPress={(e) => handleUsecaseUpdate(e, params)}
          style={{ width: '100%', padding: '4px' }}
        />
      ),
    },
    {
      field: "virtual_number_range",
      headerName: "Range",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link to={`/number-management?rangeId=${params.row.virtual_number_range}`} style={{ textDecoration: 'none', color: '#1976d2' }}>
          {params.row.virtual_number_range}
        </Link>
      ),
    },
  ];

  const handleCellClick = (params) => {
    const selectedNumber = params.row.number;
    if (params.row.status === 'Assigned') {
      setModalVisible(true);
      setSelectedRowIndex(selectedNumber);
    }
  };

  const handleSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  return (
    <div className={`${pageStyles.page_container} body_gap`}>
      <Breadcrumbs className={commonStyles.breadcrumbs} aria-label="breadcrumb">
        <p>Virtual Numbers ({filteredVirtualNumbers.length})</p>
      </Breadcrumbs>

      <Accordion
        expanded={showModal}
        onChange={(e, isExpanded) => setShowModal(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "#F0FCF4" }}
        >
          <Typography sx={{ color: "#34cb65" }}>Add virtual number</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ height: "400px" }}>
          {showModal && (
            <div className={styles.centeredWrapper}>
              <UploadVNModal
                onClose={() => setShowModal(false)}
                handleFeedback={handleFeedback}
                fetchData={fetchData}
                UploadTab={true} 
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <div className={styles.accounts_list}>
        {showLoader ? (
          <div className={styles.loader_wrapper}>
            <CircularProgress color="success" size={60} />
          </div>
        ) : (
          <>
            {selectedRows.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteVirtualNumbers}
                >
                  <DeleteOutlineRoundedIcon />
                </Button>
              </div>
            )}
            <CustomTable
              noRowsOverlayText={accountId ? "No Virtual Numbers Found for this Account ID" : "No Virtual Numbers Available"}
              rows={filteredVirtualNumbers}
              onRowClick={handleCellClick}
              onSelectionModelChange={handleSelection}
              columns={columns}
              exportOption={true}
              pageSize={14}
              showFilters={true}
              style={{ overflowX: "auto" }}
            />
          </>
        )}
      </div>

      <CustomModal
        closeModal={() => {
          setModalVisible(false);
          setShowTextFeild(false);
        }}
        visible={modalVisible}
        bodyStyles={{
          padding: "1em",
          height: "80vh",
          overflow: "auto",
        }}
        heading={"Virtual Number Details"}
        body={<VirtualNumberDetails virtualNumber={selectedRowIndex} />}
      />

      <CustomModal
        closeModal={() => setShowDeleteConfirmationModal(false)}
        visible={showDeleteConfirmationModal}
        bodyStyles={{
          padding: "1em",
          height: "auto",
          overflow: "auto",
        }}
        heading={"Confirm Delete"}
        body={
          <div>
            <p>Are you sure you want to delete the selected virtual numbers?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleConfirmDelete}
              >
                Confirm
              </Button>
            </div>
          </div>
        }
      />

      {showSnackbar && (
        <Snackbar
          message={typeof response.message === 'object' ? response.message.detail : response.message}
          type={response.success ? 'success' : 'error'}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}

export default VirtualNumbers;
