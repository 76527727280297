import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Navbar from "./components/navbar/Navbar";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import axios from "axios";
import "./index.css";
import AccountsList from "./pages/Accounts/accounts";
import AccountDetails from "./pages/Accounts/AccountDetails/accountDetails";
import VirtualNumbers from "./pages/VirtualNumbers/virtualNumbers";
import Search from "./pages/Search/search";
import IAMUsers from "./pages/Iam/iamUsers";
import  Test from "./pages/Test/test"
import NumberManagement from "./pages/VirtualNumbers/VNmanagement";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export default function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/login" element={<Login />} />

        {/* Organization Pages */}
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <AccountsList />
            </ProtectedRoute>
          }
        />
         <Route
          exact
          path="/number-management"
          element={
            <ProtectedRoute>
              <NumberManagement/>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/account/:accountId"
          element={
            <ProtectedRoute>
              <AccountDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/virtual-numbers"
          element={
            <ProtectedRoute>
              <VirtualNumbers />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/iam"
          element={
            <ProtectedRoute>
              <IAMUsers />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/search"
          element={
            <ProtectedRoute>
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/test"
          element={
            <ProtectedRoute>
              <Test />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
